import * as Sentry from '@sentry/gatsby';
import packageJson from './package.json';

Sentry.init({
  dsn: 'https://8707812f09314851ae8fee2c9276f89b@o274448.ingest.sentry.io/5799329',
  environment: 'frontend',
  release: `bbwebsite@${packageJson.version}`,
  sampleRate: 1.0,
  beforeSend(event) {
    let changedEvent: boolean = false;

    // For certain browsers, change the event SeverityLevel
    if (
      ['HeadlessChrome', 'XiaoMi/MiuiBrowser'].some(
        (ele) => window.navigator.userAgent.indexOf(ele) !== -1,
      )
    ) {
      changedEvent = true;
      console.info('User Agent: ', window.navigator.userAgent);
    }

    // We are not interested in ChunkLoadErrors as there is not much we can do (or is there!?)
    if (
      ['ChunkLoadError', 'Loading chunk'].some(
        (needle) =>
          event.message?.includes(needle) ||
          event.exception?.values?.some(
            (excVal) =>
              excVal.type?.includes(needle) || excVal.value?.includes(needle),
          ),
      )
    ) {
      changedEvent = true;
    }

    // We can ignore errors from redirected URLs
    if (
      [
        'blog/graphic/cinema-4d-lighting-render-tutorial-part-1',
        'blog/game-development/optimize-build-size-mobile-games-unity',
        'blog/work-culture/home-office-starter-kit',
        'blog/work-culture/big-agency-vs-small-agency-whats-best-for-you',
        'blog/work-culture/maximum-productivity-guide',
      ].some((ele) => event.request?.url?.includes(ele))
    ) {
      changedEvent = true;
    }

    // Sometimes the Umami server is not reachable. We can ignore those errors.
    if (
      event.message?.includes('Failed to fetch') &&
      event.exception?.values?.find(
        (val) =>
          val.stacktrace?.frames?.[0]?.abs_path ===
          'https://analytics.busybytes.app/umami.js',
      )
    ) {
      changedEvent = true;
    }

    if (changedEvent) {
      console.warn('Change Sentry event severity level to INFO.', event);
      event.level = 'info' as Sentry.SeverityLevel;
    }

    return event;
  },
});
